@import '@/_styles/import';
$base-button-size: rem(40);
$base-icon-size: rem(20);
$base-gap: rem(15);
$alt-button-size: rem(60);
$alt-icon-size: rem(30);
$alt-gap: rem(30);

.ShareLinkSet {
  position: relative;
  width: fit-content;

  &__title {
    margin: 0 0 rem(12);

    font-size: rem(16);
    font-weight: 700;
    line-height: rem(25);
    color: $black;
    text-align: center;
  }

  &__items {
    display: flex;
    flex-direction: row;
    column-gap: $base-gap;
    align-items: center;
    justify-content: space-between;

    margin: 0;
    padding: 0;

    list-style: none;
  }

  &._alt &__items {
    column-gap: $alt-gap;

    @include media-breakpoint-down(sm) {
      column-gap: $base-gap;
    }
  }

  &__copy-status {
    position: absolute;
    bottom: rem(-46);
    left: 50%;
    transform: translate(-50%, 0);

    display: flex;
    align-items: center;
    justify-content: center;

    width: rem(130);
    height: rem(30);

    font-size: rem(15);
    font-weight: 700;
    line-height: rem(25);
    color: $white;
    white-space: nowrap;

    background-color: $vv-red;
    border-radius: rem(15);
    box-shadow: 0 2px 4px $black-003;

    animation: fadeIn ease-out 0.5s;

    .Icon {
      width: rem(17);
      height: rem(17);
      margin-left: rem(5);
      line-height: 0;
    }
  }
}

.ShareLinkSet-Item {
  cursor: pointer;

  display: flex;

  width: $base-button-size;
  height: $base-button-size;

  background-color: $white;
  border-radius: 50%;
  box-shadow: 0 2px 4px $black-003;

  &._disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  > * {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    padding: 0;

    background-color: inherit;
    border: 0;
    border-radius: 50%;
  }

  .Icon {
    width: $base-icon-size;
    height: $base-icon-size;
    line-height: $base-icon-size;

    svg {
      fill: currentcolor;
    }
  }

  &._alt {
    width: $alt-button-size;
    height: $alt-button-size;

    .Icon {
      width: $alt-icon-size;
      height: $alt-icon-size;

      @include media-breakpoint-down(sm) {
        width: $base-icon-size;
        height: $base-icon-size;
      }
    }

    @include media-breakpoint-down(sm) {
      width: $base-button-size;
      height: $base-button-size;
    }
  }

  &.ShareLinkSet-Clipboard {
    .Icon {
      color: $vv-red;
    }

    &._alt {
      background-color: $vv-red;

      .Icon {
        color: $white;
      }
    }
  }

  &.ShareLinkSet-Email {
    .Icon {
      color: $email;
    }

    &._alt {
      background-color: $email;

      .Icon {
        color: $white;
      }
    }
  }

  &.ShareLinkSet-Facebook {
    .Icon {
      color: $facebook;
    }

    &._alt {
      background-color: $facebook;

      .Icon {
        color: $white;
      }
    }
  }

  &.ShareLinkSet-XTwitter {
    .Icon {
      color: $black-deep;
    }
  }
}
